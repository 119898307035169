export function removeDuplicates(a, getKey) {
  const result = []
  const seen = new Set()

  for (const x of a) {
    const key = getKey(x)
    if (seen.has(key))
      continue

    seen.add(key)
    result.push(x)
  }

  return result
}
