import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { removeDuplicates } from '/machinery/removeDuplicates'

import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingFitContainer } from '/features/buildingBlocks/HeadingFitContainer'
import { Image } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './FeaturedStories.css'

export function FeaturedStories({ items, styleContext, layoutClassName = undefined }) {
  return (
    <Container {...{ styleContext, layoutClassName }}>
      <section data-x='featured-stories-slider' className={styles.component}>
        <HeadingFitContainer h={3} title='Meer lezen' />
        <CardsSlider layoutClassName={styles.storyCardsLayout} {...{ items }} />
      </section>
    </Container>
  )
}

function Container({ styleContext, children, layoutClassName }) {
  return (
    <div className={cx(styles.componentContainer, layoutClassName)} data-style-context={styleContext}>
      <ContainerXl>{children}</ContainerXl>
    </div>
  )
}

function CardsSlider({ items, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const viewportXl = useMediaQuery(mediaStyles.viewportXl) ?? false

  const slidesPerView = isViewportMd ? 2.1 : 1.1
  const initialSlide = 0
  const sliderDisabled = viewportXl || slidesPerView >= items.length

  const { sliderRef, isMounted } = useKeenSliderRefs({
    slidesNum: items.length,
    slidesPerView,
    spacing: 18,
    initialSlide,
    sliderDisabled,
    origin: 0,
    loop: false,
    interactionTitle: 'featured-stories-slider',
  })

  return (
    <div ref={sliderRef} className={cx(
      styles.componentCardsSlider,
      sliderDisabled && styles.sliderDisabled,
      isMounted && styles.isMounted,
      'keen-slider',
      layoutClassName
    )}>
      {items.map((item, i) => (
        <div key={i} className={cx(styles.slideContainer, 'keen-slider__slide')}>
          <StoryCard
            title={item.shortTitle}
            image={item.image}
            href={determineDocumentPathSync({ document: item, routeMap })}
            layoutClassName={styles.cardLayout}
          />
        </div>
      ))}
    </div>
  )
}

function StoryCard({ image, title, href, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentStoryCard, layoutClassName)}>
      {image?.asset && <Image layoutClassName={styles.imageLayout} {...{ image }} />}

      <a className={styles.link} {...{ href }} data-x='link-to-story'>
        {title && <h3 className={styles.title}>{title}</h3>}
      </a>

      <p className={styles.readMoreLabel}>{__`read-more`}</p>
    </div>
  )
}

export function selectFeaturedStories(...storyGroups) {
  return removeDuplicates(storyGroups.flat().filter(Boolean), x => x._id).slice(0, 4)
}
