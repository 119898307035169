import styles from './HeadingFitContainer.css'

export function HeadingFitContainer({ title, h }) {
  /** @type {any} */
  const HBase = `h${h}`

  return (
    <HBase className={styles.component}>
      <span className={styles.visibleTitle}>
        <span className={styles.text}>
          <span className={styles.visibleTitle}>
            <span>{title}</span>
          </span>
          <span aria-hidden='true' className={styles.hiddenTitle}>{title}</span>
        </span>
      </span>
      <span aria-hidden='true' className={styles.hiddenTitle}>{title}</span>
    </HBase>
  )
}
